import React from 'react';
import tabs from '../hero/components/TabData';
import useSharedTabState from '../../hooks/tabState';
import Zipform from '../core/zipform/ZipformFlowB';
import PlanPrescriber from '../plan-prescriber/PlanPrescriber';
import ExpansionTab from '../expansion-tab/ExpansionTab';
import Trustpilot from '../trustpilot/Trustpilot';
import useWindowResize from '../../hooks/useWindowResize';
import constants from '../../globals/constants/application-constants';

const QA_SECTION = 'Hero';
interface BottomTxtProps {
  serviceHours: string;
}
const BottomTxt = ({ serviceHours }: BottomTxtProps): JSX.Element => {
  const { active, setActive } = useSharedTabState();
  const windowInnerwidth = useWindowResize(true);
  const isMobile = windowInnerwidth < constants.TABLET_BREAKPOINT;
  return (
    <div>
      {active === 0 && <Trustpilot />}
      {active === 0 && <PlanPrescriber />}
      <section
        className="px-4 pt-7 pb-16 md:px-6 md:pt-6 md:pb-12 lg:py-16 bg-gray-lightest"
        data-wa-depth="Find your health insurance match: depthTracking"
      >
        <h2 className="max-w-screen-xl mx-auto overflow-hidden text-20 leading-28 md:text-24 md:leading-32 xl:leading-56 xl:text-40 font-bold text-center text-green-darker mb-6 md:mb-0">
          Find your health insurance match
        </h2>
        {!isMobile && (
          <div className="hidden md:block w-full lg:max-w-[830px] xl:max-w-[1200px] mx-auto mt-12">
            {/* Tabs: Mobile / Tablet / Desktop */}
            <div className="tabs">
              <div className="tablist" role="tablist" aria-label="Insurance Types">
                <span className="flex w:full">
                  {tabs.map((tab, index) => (
                    <button
                      type="button"
                      role="tab"
                      key={`desktopTab-${tab.name}`}
                      data-wa-link={`footer tab: ${tab.hero.type}`}
                      data-qa={`hero-${tab.hero.type}-tab`}
                      className={`md:w-[99px] md:h-[131px] font-poppin xl:w-[146px] xl:h-[130px] mb-[26px] flex-grow text-16 leading-18 py-[10px] shadow-md rounded-3xl hover:bg-[#D3E2D3] hover:border-[3px] hover:border-green-800 font-bold  ${
                        tab.hero.type !== 'st' ? 'mr-[20px]' : ''
                      } ${
                        active === index
                          ? 'bg-white border-[3px] px-[9px] border-green-800 active-tab relative'
                          : 'bg-[#FCFDFD] text-gray-dark px-3 hover:px-[9px]'
                      }`}
                      onClick={() => setActive(index)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setActive(index);
                        }
                      }}
                    >
                      <img src={tab.icon} className="block mx-auto mb-2" alt="" />
                      <h3>{tab.name}</h3>
                    </button>
                  ))}
                </span>
              </div>
            </div>

            {/* Zipform Container */}
            <div
              id="zipform-bottom-wrapper"
              className="bg-white shadow-lg rounded-3xl max-w-[1200px]"
            >
              <div className="p-4 md:pb-0 md:pt-6 md:px-8 lg:px-12 xl:px-0 min-h-[144px] m-auto xl:w-[790px]">
                <Zipform
                  qaSection={QA_SECTION}
                  insuranceType={tabs[active].hero.type}
                  position="footer"
                  serviceHours={serviceHours}
                />
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className="md:hidden lg:hidden">
            {tabs.map((tab, index) => {
              return (
                <div key={`mobileTab-${tab.name}`}>
                  <ExpansionTab
                    position="footer"
                    qaSection={QA_SECTION}
                    tab={tab}
                    index={index}
                    serviceHours={serviceHours}
                  />
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
};
export default BottomTxt;
