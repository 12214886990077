import * as React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../globals/constants/application-constants';
import envConstants from '../../../globals/constants/environment-constants';
import { getBrowserStorage, setBrowserStorage, initInvoca, runInvoca } from '../../../common';
import useWindowResize from '../../../hooks/useWindowResize';
import TopBanner from '../../top-banner/TopBanner';
import useSharedTabState from '../../../hooks/tabState';
import { AppContext } from '../../../globals/context/AppContext';
import getPartnerAllianceInfo from '../../../partner-alliance-info';

interface ILayoutProps {
  children: React.ReactNode;
}

interface ILocation {
  cityName: string;
  countyName: string;
  fipsCode: string;
  savingsAmount: string;
  savingsLevel: string;
  ssacd: string;
  stateAbbr: string;
  stateName: string;
}
enum LocationErrorType {
  none,
  noCounty,
  zipEmpty,
  zipInvalid,
}

const isBrowser = typeof window !== 'undefined';
let allid = '';
if (isBrowser) {
  allid = getBrowserStorage('allid');
}

const MainLayout = ({ children }: ILayoutProps): JSX.Element => {
  const [hasBanner, setHasBanner] = React.useState(false);
  const windowInnerwidth = useWindowResize(true);
  const [mainOffset, setMainOffset] = React.useState(windowInnerwidth < 1200 ? 77 : 120);
  const { active } = useSharedTabState();
  const { setPhone, setPartnerAllianceInfo, globalZipValue, setGlobalZipValue } =
    React.useContext(AppContext);
  const [locationsUrl, setLocationsUrl] = React.useState('');
  const [countyValue, setCountyValue] = React.useState('');
  const [hasLocationError, setHasLocationError] = React.useState(LocationErrorType.none);
  const [multiCountyList, setMultiCountyList] = React.useState([] as ILocation[]);
  React.useEffect(() => {
    setLocationsUrl(`${envConstants.MCWS_URL}`);
  }, []);

  const handleSetMainMarginTop = () => {
    const headerHeight = windowInnerwidth < 1200 ? 77 : 120;
    if (hasBanner) {
      const bannerHeight = document
        .getElementsByClassName('alert')[0]
        ?.getBoundingClientRect().height;
      setMainOffset(headerHeight + bannerHeight);
    } else {
      setMainOffset(headerHeight);
    }
  };

  const loadInvocaDone = (tryCount: number) => {
    const { Invoca } = window as any;
    if (
      Invoca &&
      Invoca.PNAPI &&
      Invoca.PNAPI.currentPageSettings &&
      Invoca.PNAPI.currentPageSettings.networkId
    ) {
      Invoca.PNAPI.currentPageSettings.onComplete = function () {
        // eslint-disable-next-line prefer-rest-params
        const invocaPhone = arguments[0][0].formattedNumber;
        if (invocaPhone) {
          setPhone({ phoneNumber: `1-${invocaPhone}`, isFinal: true });
        }
      };
    } else if (tryCount < 10) {
      setTimeout(() => {
        loadInvocaDone(tryCount + 1);
      }, 500);
    } else {
      setPhone({ phoneNumber: constants.DEFAULT_PHONE, isFinal: true });
    }
  };

  const getPartnerAllianceInfoDone = async () => {
    setPartnerAllianceInfo(await getPartnerAllianceInfo());
  };
  const fetchCounty = async (zip: string) => {
    try {
      const response = await fetch(`${locationsUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query:
            'query areaByZipCode($zip: String!) {\n' +
            '    areaByZipCode(zipCode: $zip) {\n' +
            '        zip\n' +
            '        locationList {\n' +
            '            ssacd\n' +
            '            stateAbbr\n' +
            '            countyName\n' +
            '            fipsCode\n' +
            '            cityName\n' +
            '            stateName\n' +
            '        }\n' +
            '        hasError\n' +
            '        message\n' +
            '    }\n' +
            '}\n',
          variables: { zip },
        }),
      });
      const parsed: {
        data: {
          areaByZipCode: {
            zip: string;
            locationList: ILocation[];
            hasError: boolean;
            message: string;
          };
        };
      } = await response?.json();
      if (!response.ok || parsed.data.areaByZipCode.hasError) {
        throw new Error();
      }
      setHasLocationError(LocationErrorType.none);

      if (parsed.data.areaByZipCode.locationList.length === 1) {
        setBrowserStorage('zipValue', zip);
        setGlobalZipValue(zip);
        setCountyValue(parsed.data.areaByZipCode.locationList[0].countyName);
        setBrowserStorage('countyValue', parsed.data.areaByZipCode.locationList[0].countyName);
      } else {
        setBrowserStorage('zipValue', zip);
        setGlobalZipValue(zip);
        setMultiCountyList(parsed.data.areaByZipCode.locationList);
        setBrowserStorage(
          'multiCountyList',
          JSON.stringify(parsed.data.areaByZipCode.locationList),
        );
      }
    } catch (e) {
      setHasLocationError(LocationErrorType.zipInvalid);
    }
  };

  React.useEffect(() => {
    getPartnerAllianceInfoDone();
    initInvoca();
    loadInvocaDone(0);
    setHasBanner(envConstants.SUBDOMAIN?.length > 0);
    handleSetMainMarginTop();
  }, []);

  React.useLayoutEffect(() => {
    handleSetMainMarginTop();
  }, [windowInnerwidth]);

  React.useEffect(() => {
    runInvoca(active);
  }, [active]);
  React.useEffect(() => {
    if (
      !countyValue &&
      multiCountyList.length === 0 &&
      locationsUrl &&
      globalZipValue &&
      globalZipValue.length === 5
    ) {
      setMultiCountyList([]);
      setCountyValue('');
      fetchCounty(globalZipValue);
    }
  }, [globalZipValue, locationsUrl]);
  return (
    <div>
      <div className="header-wrapper">
        {hasBanner ? <TopBanner /> : ''}
        <ehi-header
          allianceId={allid}
          page="home"
          menuOverlayInheritMarginTop={hasBanner ? 'Y' : 'N'}
          activetab={`tab-${active}`}
          version="v2"
        >
          <a
            slot="invoca-header--mobile"
            className="invoca-phone-number phone-number phone--mobile"
            href={`tel:${constants.DEFAULT_PHONE.replaceAll(/\D/g, '')}`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2948_7069)">
                <path
                  d="M8.86606 8.16386C10.2895 6.74053 10.2895 4.43107 8.86606 3.00589L6.92753 1.0675C5.50409 -0.355833 3.19447 -0.355833 1.76918 1.0675L1.69349 1.14319C-1.68325 4.88521 0.133432 12.4043 5.8641 18.1364C11.5966 23.8685 19.1163 25.6832 22.8567 22.3049L22.9324 22.2292C24.3559 20.8059 24.3559 18.4964 22.9324 17.0712L20.9939 15.1328C19.5705 13.7095 17.2608 13.7095 15.8355 15.1328L14.1998 16.7685L7.22846 9.79765L8.86421 8.16202L8.86606 8.16386Z"
                  fill="#0C6E1E"
                />
              </g>
              <defs>
                <clipPath id="clip0_2948_7069">
                  <path d="M0 0H24V24H0V0Z" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{constants.DEFAULT_PHONE} TTY users 711</span>
          </a>
          <div slot="invoca-header--tablet" className="invoca-phone-number phone-number">
            {constants.DEFAULT_PHONE}
          </div>
          <div slot="invoca-header--desktop" className="invoca-phone-number phone-number">
            {constants.DEFAULT_PHONE}
          </div>
        </ehi-header>
      </div>
      <main style={{ marginTop: `${mainOffset}px` }} className="mt-[77px] xl:mt-[120px]">
        {children}
      </main>
      <ehi-footer allianceId={allid} page="home" showMedDisclaimer={active === 0} version="v2">
        <div id="norton__footer" slot="norton__footer">
          <div id="DigiCertClickID_onwANETs_1" />
        </div>
      </ehi-footer>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
