import * as React from 'react';
import constants from '../constants/application-constants';
import { getBrowserStorage, getCookieStorage } from '../../common';

interface InvocaPhone {
  phoneNumber: string;
  isFinal: boolean;
}

export const AppContext = React.createContext<{
  phone: InvocaPhone;
  setPhone: React.Dispatch<React.SetStateAction<InvocaPhone>>;
  partnerAllianceInfo: AlliancePageConfigResponse;
  setPartnerAllianceInfo: React.Dispatch<React.SetStateAction<AlliancePageConfigResponse>>;
  globalZipValue: string;
  setGlobalZipValue: React.Dispatch<React.SetStateAction<string>>;
} | null>(null);

const AppProvider: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  const [phone, setPhone] = React.useState({
    phoneNumber: constants.DEFAULT_PHONE,
    isFinal: false,
  });
  const [globalZipValue, setGlobalZipValue] = React.useState(() => {
    let storedZip = getBrowserStorage('zipValue');
    const cookieZip = getCookieStorage('mockZipCode');
    if (cookieZip) {
      storedZip = JSON.parse(cookieZip).zipCode;
    }
    if (storedZip) {
      return storedZip;
    }
    return '';
  });

  const defaultPartnerAllianceInfo: AlliancePageConfigResponse = {
    data: {
      partnerAllianceInfo: {
        suppressEmailCapturePopup: '',
        allianceData: {
          partnerDisplayName: '',
          serviceHours: '',
          throttle: 'GREEN',
          allianceInfo: {
            allianceLogo: '',
            allianceCompanyUrl: '',
            tcpaDisclaimer: '',
          },
        },
        lightModeHeader: '',
        partnershipType: '',
        segmentType: '',
        landingPageConfigs: [
          {
            pageType: '',
            paramKey: '',
            paramValue: '',
          },
        ],
      },
    },
  };

  const [partnerAllianceInfo, setPartnerAllianceInfo] = React.useState(defaultPartnerAllianceInfo);

  return (
    <AppContext.Provider
      value={{
        phone,
        setPhone,
        partnerAllianceInfo,
        globalZipValue,
        setGlobalZipValue,
        setPartnerAllianceInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;
